	/*
  	Flaticon icon font: Flaticon
  	Creation date: 15/11/2019 18:02
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("Flaticon.eot");
  src: url("Flaticond41d.eot?#iefix") format("embedded-opentype"),
       url("Flaticon.woff2") format("woff2"),
       url("Flaticon.woff") format("woff"),
       url("Flaticon.ttf") format("truetype"),
       url("Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-placeholder:before { content: "\f100"; }
.flaticon-email:before { content: "\f101"; }
.flaticon-telephone:before { content: "\f102"; }
.flaticon-right-quote:before { content: "\f103"; }
.flaticon-download:before { content: "\f104"; }