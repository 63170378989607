/*
Theme Name: Ree
Theme URI: http://www.affixtheme.com/html/ree
Author: AffixTheme
Author URI: http://www.affixtheme.com/
Description: Ree | Personal vCard/CV Portfolio Html Template + Rtl
Version: 1.0.0
  
----------------------------------------------------------------
>>> TABLE OF CONTENTS
----------------------------------------------------------------
01. Typography
02. Global
03. Header
04. Banner Section
05. Video Background
06. About Section 
07. Skill Section 
08. Service Section 
09. Testimonial Section 
10. Portfolio Section
11. Progress Section
12. Pricing Section
13. Blog Section
14. Contact Section
15. Footer
16. Sidebar
17. Animation Style
18. Switcher Style
  
--------------------------------------------------------=------*/
/*=======================================================================
01. Typography 
=========================================================================*/
body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 30px;
  color: var(--bs-text-secondary-white);
  font-weight: 400;
  height: 100%;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background-color: var(--bs-white-color-bg-theme);
}
p {
  margin: 0 0 1.25rem 0;
  color: var(--bs-text-secondary-white);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif;
  margin: 0 0 20px 0;
  font-weight: 400;
  color: var(--bs-white-color-text-theme);
}
h1 {
  font-size: 52px;
  line-height: 64px;
}
@media only screen and (max-width: 1199px) {
  h1 {
    font-size: 46px;
    line-height: 58px;
  }
}
@media only screen and (max-width: 991px) {
  h1 {
    font-size: 40px;
    line-height: 52px;
  }
}
@media only screen and (max-width: 767px) {
  h1 {
    font-size: 34px;
    line-height: 46px;
  }
}
@media only screen and (max-width: 575px) {
  h1 {
    font-size: 30px;
    line-height: 42px;
  }
}
h2 {
  font-size: 36px;
  line-height: 48px;
}
@media only screen and (max-width: 991px) {
  h2 {
    font-size: 32px;
    line-height: 44px;
  }
}
@media only screen and (max-width: 767px) {
  h2 {
    font-size: 28px;
    line-height: 40px;
  }
}
@media only screen and (max-width: 575px) {
  h2 {
    font-size: 26px;
    line-height: 38px;
  }
}
h3 {
  font-size: 22px;
  line-height: 34px;
}
@media only screen and (max-width: 991px) {
  h3 {
    font-size: 20px;
    line-height: 30px;
  }
}
@media only screen and (max-width: 767px) {
  h3 {
    font-size: 18px;
    line-height: 28px;
  }
}
h4 {
  font-size: 20px;
  line-height: 30px;
}
@media only screen and (max-width: 991px) {
  h4 {
    font-size: 18px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 767px) {
  h4 {
    font-size: 17px;
    line-height: 27px;
  }
}
h5 {
  font-size: 18px;
  line-height: 28px;
}
@media only screen and (max-width: 991px) {
  h5 {
    font-size: 17px;
    line-height: 27px;
  }
}
@media only screen and (max-width: 767px) {
  h5 {
    font-size: 16px;
    line-height: 26px;
  }
}
h6 {
  font-size: 16px;
  line-height: 26px;
}
/*=======================================================================
02. Global 
=========================================================================*/
a {
  text-decoration: none;
  cursor: pointer;
}
a:active,
a:hover,
a:focus {
  text-decoration: none;
}
a:active,
a:hover,
a:focus {
  outline: 0 none;
}
img {
  max-width: 100%;
  height: auto;
}
ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
hr {
  margin: 30px 0 0;
}
[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  margin-left: 0;
}
.item-icon {
  line-height: 1;
}
.form-response .alert-danger,
.form-response .alert.alert-info,
.form-response .alert.alert-success {
  margin-top: 10px;
  margin-bottom: 0;
}
.form-response .alert-danger p,
.form-response .alert.alert-info p,
.form-response .alert.alert-success p {
  margin-bottom: 0;
}
.bg-common {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}
.bg-size-cover {
  background-size: cover;
}
.bg-attachment-fixed {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
}
.bg-color-1 {
  background-color: var(--bs-bg-color-theme);
}
.bg-color-2 {
  background-color: #090d24;
}
.bg-color-3 {
  background-color: var(--bs-bg-color-theme-secondary);
}
.bg-color-4 {
  background-color: var(--bs-bg-color-theme-secondary-2);
}
.bg-color-5 {
  background-color: var(--bs-white-color-bg-theme);
}
.btn-fill {
  display: inline-block;
  background-color: var(--bs-default-theme);
  font-size: 17px;
  font-weight: 600;
  color: var(--bs-white-color-text-theme);
  padding: 8px 22px;
  border: 1px solid var(--bs-default-theme);
  transition: all 0.3s ease-in-out;
}
.btn-fill i:before {
  font-weight: 400;
  margin-left: 10px;
  font-size: 20px;
}
.btn-fill:hover {
  background-color: var(--bs-bg-transparent);
  color: var(--bs-default-theme);
}
.btn-text {
  display: inline-block;
  color: var(--bs-text-secondary-white);
  font-weight: 600;
  padding: 5px 10px 5px 0;
  transition: all 0.3s ease-in-out;
}
.btn-text:hover {
  color: var(--bs-default-theme);
}
.isotop-btn {
  position: relative;
  z-index: 1;
}
.isotop-btn:before {
  border-bottom: 1px solid #1d2137;
  content: "";
  bottom: 27px;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 2;
}
.isotop-btn a.nav-item {
  margin-bottom: 30px;
  font-weight: 600;
  color: var(--bs-text-secondary-white);
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 20px;
  display: inline-block;
  position: relative;
  transition: all 0.3s ease-in-out;
}
@media only screen and (max-width: 991px) {
  .isotop-btn a.nav-item {
    display: none;
  }
}
.isotop-btn a.nav-item:after {
  content: "";
  height: 2px;
  width: 100%;
  background-color: var(--bs-default-theme);
  position: absolute;
  bottom: -2px;
  left: 100px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
@media only screen and (max-width: 767px) {
  .isotop-btn a.nav-item:after {
    display: none;
  }
}
.isotop-btn a.nav-item:hover {
  color: var(--bs-default-theme);
}
.isotop-btn a.current.nav-item {
  color: var(--bs-default-theme);
}
.isotop-btn a.current.nav-item:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
section {
  padding-top: 88px;
  padding-bottom: 70px;
}
@media only screen and (max-width: 991px) {
  section {
    padding-top: 78px;
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 767px) {
  section {
    padding-top: 68px;
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 575px) {
  section {
    padding-top: 58px;
    padding-bottom: 40px;
  }
}
.fxt-heading-layout1 {
  position: relative;
  z-index: 1;
  text-align: center;
  margin-bottom: 60px;
}
.fxt-heading-layout1 .heading-title {
  color: var(--bs-white-color-text-theme);
  font-weight: 700;
  margin-bottom: 10px;
}
.fxt-heading-layout1 .heading-paragraph {
  color: var(--bs-white-secondary-theme);
  margin-bottom: 20px;
}
.fxt-heading-layout1 .heading-line {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: 1px solid var(--bs-default-theme);
  margin: 0 auto;
  position: relative;
}
.fxt-heading-layout1 .heading-line:before,
.fxt-heading-layout1 .heading-line:after {
  content: "";
  height: 2px;
  width: 60px;
  background-color: #1d2137;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.fxt-heading-layout1 .heading-line:before {
  left: 16px;
}
.fxt-heading-layout1 .heading-line:after {
  right: 16px;
}
.dot-control-layout1 .owl-dots {
  text-align: center;
  margin-top: 20px;
  line-height: 0;
  padding-bottom: 30px;
}
.dot-control-layout1 .owl-dots .owl-dot {
  margin: 0 2px;
  padding: 0 2px!important;
}
.dot-control-layout1 .owl-dots .owl-dot span {
  height: 12px;
  width: 12px;
  background-color: var(--bs-bg-transparent);
  border: 2px solid rgba(254, 156, 44, 0.3);
  border-radius: 50%;
  display: block;
  transition: all 0.3s ease-in-out;
}
.dot-control-layout1 .owl-dots .owl-dot:focus {
  outline: none;
}
.dot-control-layout1 .owl-dots .owl-dot.active span {
  background-color: var(--bs-default-theme);
}
.active-custom-style .preloader-gif {
  display: none;
}
.active-gif .preloader-custom-style {
  display: none;
}
.fxt-page-banner {
  display: block;
  padding: 242px 0 139px;
  position: relative;
  z-index: 1;
}
.fxt-page-banner:before {
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1;
  background-color: rgba(20, 25, 56, 0.85);
}
@media only screen and (max-width: 991px) {
  .fxt-page-banner {
    padding: 122px 0 119px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-page-banner {
    padding: 102px 0 99px;
  }
}
@media only screen and (max-width: 575px) {
  .fxt-page-banner {
    padding: 82px 0 79px;
  }
}
.fxt-page-banner .breadcrumbs-area {
  position: relative;
  z-index: 1;
  text-align: center;
}
.fxt-page-banner .breadcrumbs-area h1 {
  font-weight: 600;
  color: var(--bs-white-color-text-theme);
  margin-bottom: 15px;
}
.fxt-page-banner .breadcrumbs-area ul li {
  display: inline-block;
  position: relative;
  margin-right: 15px;
  padding-right: 5px;
  color: var(--bs-default-theme);
  font-size: 18px;
}
@media only screen and (max-width: 767px) {
  .fxt-page-banner .breadcrumbs-area ul li {
    font-size: 18px;
  }
}
.fxt-page-banner .breadcrumbs-area ul li:before {
  content: "/";
  position: absolute;
  font-size: 18px;
  right: -11px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 5;
  color: var(--bs-white-color-text-theme);
}
.fxt-page-banner .breadcrumbs-area ul li a {
  color: var(--bs-white-color-text-theme);
  transition: all 0.3s ease-in-out;
}
.fxt-page-banner .breadcrumbs-area ul li a:hover {
  color: var(--bs-default-theme);
}
.fxt-page-banner .breadcrumbs-area ul li:last-child {
  margin-right: 0;
  padding-right: 0;
}
.fxt-page-banner .breadcrumbs-area ul li:last-child:before {
  display: none;
}
/*=======================================================================
03. Header 
=========================================================================*/
.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 100;
  padding: 20px 0;
  transition: all 0.5s ease-in-out;
}
.sticky-header:after {
  clear: both;
  content: "";
  display: block;
}
.sticky-header .default-logo {
  display: block;
}
.sticky-header .sticky-logo {
  display: none;
}
.sticky-header nav.template-main-menu ul li {
  display: inline-block;
}
.sticky-header nav.template-main-menu ul li:last-child a {
  padding-right: 0;
}
.sticky-header nav.template-main-menu ul li a {
  font-weight: 600;
  font-size: 17px;
  color: var(--bs-white-color-text-theme);
  padding: 20px 15px;
  display: block;
  position: relative;
  transition: all 0.5s ease-in-out;
}
.sticky-header nav.template-main-menu ul li a:after {
  content: "";
  height: 4px;
  width: 0;
  background-color: var(--bs-default-theme);
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.sticky-header nav.template-main-menu ul li:hover a {
  color: var(--bs-default-theme);
}
.sticky-header nav.template-main-menu ul li.current a {
  color: var(--bs-default-theme);
}
.sticky-header nav.template-main-menu ul li.current a:after {
  visibility: visible;
  opacity: 1;
  width: 20px;
}
.sticky-header.sticky {
  padding: 0;
  box-shadow: 0 0 30px 0 rgba(104, 104, 104, 0.08);
  background-color: rgba(255, 255, 255, 0.95);
}
.sticky-header.sticky .default-logo {
  display: none;
}
.sticky-header.sticky .sticky-logo {
  display: block;
}
.sticky-header.sticky nav.template-main-menu ul li a {
  color: var(--bs-color-text-theme);
}
.sticky-header.sticky nav.template-main-menu ul li a:after {
  background-color: var(--bs-default-theme);
}
.sticky-header.sticky nav.template-main-menu ul li a:hover {
  color: var(--bs-default-theme);
}
.sticky-header.sticky nav.template-main-menu ul li.current a {
  color: var(--bs-default-theme);
}
@media only screen and (max-width: 991px) {
  header {
    display: none;
  }
}
.mean-container .mean-bar {
  background: transparent !important;
  float: none;
  padding: 0 !important;
  position: fixed !important;
  top: 0;
}
@media only screen and (max-width: 767px) {
  .mean-container .mean-bar a {
    padding: 6px 16px;
    display: inline-block;
  }
}
@media only screen and (max-width: 767px) {
  .mean-container .mean-bar .meanmenu-reveal {
    margin-top: 5px;
  }
}
.mean-container .mean-bar:after {
  content: "";
  clear: both;
  display: block;
}
.mean-container .mean-nav {
  float: none !important;
  background: transparent !important;
}
.mean-container .mean-nav > ul {
  overflow-y: scroll;
}
.mean-container .mean-nav > ul li a {
  color: var(--bs-color-text-theme);
  font-weight: 700;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.mean-container .mean-nav > ul li a:hover {
  color: var(--bs-default-theme);
}
.mean-container .mean-nav > ul li:hover > a {
  color: var(--bs-default-theme);
}
.mean-container .mean-nav > ul:after {
  content: '';
  display: block;
  clear: both;
}
.mean-container .mean-bar a.logo-mobile-menu {
  width: 100%;
  box-sizing: border-box;
}
.mean-container .mean-bar .mobile-menu-nav-back {
  padding-left: 15px;
  border-bottom: 1px solid var(--bs-default-theme);
  position: relative;
  text-align: center;
  height: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.mean-container .mean-bar .mobile-menu-nav-back:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: var(--bs-white-color-bg-theme);
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
}
.mean-container a.meanmenu-reveal {
  float: none !important;
  position: absolute;
  top: 6px;
  transition: unset;
}
.mean-bar + div #wrapper {
  padding-top: 52px;
}
.mean-container .mean-nav ul li li a {
  color: var(--bs-color-text-theme);
  opacity: 1;
  padding: 0.5em 10%;
}
.mean-container .mean-nav ul li a {
  font-size: 14px;
}
.mean-container .mean-nav ul li a.mean-expand {
  background-color: var(--bs-bg-transparent);
  margin-top: 0;
  height: 20px;
  line-height: 27px;
  width: 100%;
  text-align: right;
  font-size: 14px;
  padding: 12px 20px 12px 12px !important;
}
.mean-container .mean-nav ul li a.mean-expand:hover {
  background-color: var(--bs-bg-transparent);
}
.fxt-offcanvas-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 9999;
  height: 100vh;
  float: left;
}
.fxt-offcanvas-wrap .fxt-offcanvas-content {
  padding: 30px 15px 30px 10px;
  width: 60px;
  position: fixed;
  top: 0;
  z-index: 5;
  height: 100%;
  background-color: #141938;
  display: -webkit-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media only screen and (max-width: 767px) {
  .fxt-offcanvas-wrap .fxt-offcanvas-content {
    width: 50px;
  }
}
.fxt-offcanvas-wrap .fxt-offcanvas-content .logo-area .default-logo {
  display: block;
  width: 25px;
  height: 60px;
}
.fxt-offcanvas-wrap .fxt-offcanvas-content .sidebar-toggle-btn {
  display: block;
  background-color: var(--bs-bg-transparent);
  border: none;
  width: 40px;
  padding: 8px;
}
.fxt-offcanvas-wrap .fxt-offcanvas-content .sidebar-toggle-btn:focus {
  outline: none;
}
.fxt-offcanvas-wrap .fxt-offcanvas-content .sidebar-toggle-btn span {
  display: block;
  height: 2px;
  width: 100%;
  background-color: #c1c1c1;
  margin-bottom: 0.25rem;
  transition: all 0.3s ease-in-out;
}
.fxt-offcanvas-wrap .fxt-offcanvas-content .sidebar-toggle-btn span:nth-child(even) {
  width: 73%;
}
.fxt-offcanvas-wrap .fxt-offcanvas-content .sidebar-toggle-btn:hover span {
  background-color: var(--bs-default-theme);
}
.fxt-offcanvas-wrap .fxt-offcanvas-content .sidebar-toggle-btn:hover span:nth-child(even) {
  width: 100%;
}
.fxt-offcanvas-wrap .fxt-offcanvas-content .sidebar-toggle-btn:hover span:nth-child(odd) {
  width: 73%;
}
.fxt-offcanvas-wrap .fxt-offcanvas-nav {
  position: fixed;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 2;
  width: 240px;
  margin-left: 60px;
  transition: all 0.5s ease-in-out;
  background-color: #121633;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .fxt-offcanvas-wrap .fxt-offcanvas-nav {
    margin-left: 50px;
  }
}
.fxt-offcanvas-wrap .fxt-offcanvas-nav ul li {
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 16px;
}
.fxt-offcanvas-wrap .fxt-offcanvas-nav ul li:last-child {
  margin-bottom: 0;
}
.fxt-offcanvas-wrap .fxt-offcanvas-nav ul li a {
  color: var(--bs-white-color-text-theme);
  padding: 5px 10px;
  transition: all 0.3s ease-in-out;
}
.fxt-offcanvas-wrap .fxt-offcanvas-nav ul li a:hover {
  color: var(--bs-default-theme);
}
.fxt-offcanvas-wrap .fxt-offcanvas-nav ul li.current a {
  color: var(--bs-default-theme);
}
.fxt-main-content {
  padding-left: 300px;
  transition: all 0.5s ease-in-out;
}
@media only screen and (max-width: 1199px) {
  .fxt-main-content {
    padding-left: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-main-content {
    padding-left: 50px;
  }
}
body.close-sidebar .fxt-offcanvas-wrap .fxt-offcanvas-nav {
  left: -240px;
}
@media only screen and (max-width: 767px) {
  body.close-sidebar .fxt-offcanvas-wrap .fxt-offcanvas-nav {
    left: -250px;
  }
}
body.close-sidebar .fxt-main-content {
  padding-left: 60px;
}
@media only screen and (max-width: 767px) {
  body.close-sidebar .fxt-main-content {
    padding-left: 50px;
  }
}
/*=======================================================================
04. Banner Section
=========================================================================*/
.fxt-main-banner-section {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100vh;
}
@media only screen and (max-width: 767px) {
  .fxt-main-banner-section {
    padding-top: 0;
  }
}
.fxt-main-banner-section:after {
  content: "";
  height: 100%;
  width: 100%;
  background-color: var(--bs-bg-color-primary);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
.fxt-main-banner-box-layout1 .top-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .fxt-main-banner-box-layout1 .top-content {
    display: inherit;
    text-align: center;
  }
}
.fxt-main-banner-box-layout1 .top-content .item-img {
  margin-right: 30px;
}
@media only screen and (max-width: 767px) {
  .fxt-main-banner-box-layout1 .top-content .item-img {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.fxt-main-banner-box-layout1 .top-content .item-img img {
  border-radius: 50%;
}
.fxt-main-banner-box-layout1 .top-content .item-content .item-top-text {
  font-size: 24px;
  color: var(--bs-white-color-text-theme);
  margin-bottom: 10px;
}
@media only screen and (max-width: 991px) {
  .fxt-main-banner-box-layout1 .top-content .item-content .item-top-text {
    font-size: 22px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-main-banner-box-layout1 .top-content .item-content .item-top-text {
    font-size: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .fxt-main-banner-box-layout1 .top-content .item-content .item-top-text {
    font-size: 18px;
  }
}
.fxt-main-banner-box-layout1 .top-content .item-content .item-title {
  color: var(--bs-white-color-text-theme);
  font-weight: 800;
  margin-bottom: 0;
}
.fxt-main-banner-box-layout1 .top-content .item-content .cd-words-wrapper {
  display: inline-block;
  position: relative;
  text-align: left;
}
.fxt-main-banner-box-layout1 .top-content .item-content .cd-words-wrapper b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0;
  color: var(--bs-default-theme);
  font-size: 20px;
  font-weight: 600;
}
.fxt-main-banner-box-layout1 .top-content .item-content .cd-words-wrapper b.is-visible {
  position: relative;
}
.fxt-main-banner-box-layout1 .top-content .item-content .no-js .cd-words-wrapper b {
  opacity: 0;
}
.fxt-main-banner-box-layout1 .top-content .item-content .no-js .cd-words-wrapper b.is-visible {
  opacity: 1;
}
.fxt-main-banner-box-layout1 .top-content .item-content .cd-headline.clip span {
  display: inline-block;
}
.fxt-main-banner-box-layout1 .top-content .item-content .cd-headline.clip .cd-words-wrapper {
  overflow: hidden;
  vertical-align: top;
}
.fxt-main-banner-box-layout1 .top-content .item-content .cd-headline.clip .cd-words-wrapper::after {
  content: '';
  position: absolute;
  top: 55%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  width: 2px;
  height: 60%;
  background-color: #aebcb9;
}
.fxt-main-banner-box-layout1 .top-content .item-content .cd-headline.clip b {
  opacity: 0;
}
.fxt-main-banner-box-layout1 .top-content .item-content .cd-headline.clip b.is-visible {
  opacity: 1;
}
.fxt-main-banner-box-layout1 .top-content .item-content .item-subtitle {
  font-size: 20px;
  color: var(--bs-white-color-text-theme);
}
.fxt-main-banner-box-layout1 .bottom-content {
  padding: 0 15px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
  max-width: 1140px;
  bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  .fxt-main-banner-box-layout1 .bottom-content {
    max-width: inherit;
    display: inherit;
    left: 0;
    right: 0;
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-main-banner-box-layout1 .bottom-content .item-social {
    margin-bottom: 10px;
  }
}
.fxt-main-banner-box-layout1 .bottom-content .item-social ul li {
  display: inline-block;
  margin-right: 10px;
}
.fxt-main-banner-box-layout1 .bottom-content .item-social ul li:last-child {
  margin-right: 0;
}
.fxt-main-banner-box-layout1 .bottom-content .item-social ul li:first-child a {
  padding-left: 0;
}
.fxt-main-banner-box-layout1 .bottom-content .item-social ul li a {
  font-size: 17px;
  color: #c5c5c5;
  padding: 5px;
  transition: all 0.3s ease-in-out;
}
.fxt-main-banner-box-layout1 .bottom-content .item-social ul li a:hover {
  color: var(--bs-default-theme);
}
.fxt-main-banner-box-layout1 .bottom-content .item-website a {
  font-size: 17px;
  font-weight: 500;
  color: #c5c5c5;
  transition: all 0.3s ease-in-out;
}
.fxt-main-banner-box-layout1 .bottom-content .item-website a:hover {
  color: var(--bs-default-theme);
}
/*=======================================================================
05. Video Background 
=========================================================================*/
.fxt-video-background {
  height: 100vh;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 0;
}
.fxt-video-background:before {
  content: "";
  position: absolute;
  z-index: 2;
  background-color: rgba(20, 25, 56, 0.6);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.fxt-video-background .bg-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
}
.fxt-video-background .bg-overlay img {
  height: 100%;
  max-width: inherit;
}
/*=======================================================================
06. About Section 
=========================================================================*/
.fxt-about-box-layout1 {
  margin-bottom: 30px;
  margin-right: 15px;
  text-align: center;
}
.fxt-about-box-layout1 .item-img {
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
  margin-top: 8px;
  display: inline-block;
}
.fxt-about-box-layout1 .item-img:before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: var(--bs-default-theme);
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: -14px;
  z-index: -1;
}
.fxt-about-box-layout1 .item-img img {
  border-radius: 50%;
}
@media only screen and (max-width: 991px) {
  .fxt-about-box-layout1 .download-btn {
    margin-bottom: 30px;
  }
}
.fxt-about-box-layout2 {
  margin-bottom: 30px;
}
.fxt-about-box-layout2 .item-title {
  font-size: 26px;
  line-height: 38px;
  font-weight: 700;
  margin-bottom: 5px;
}
@media only screen and (max-width: 991px) {
  .fxt-about-box-layout2 .item-title {
    font-size: 24px;
    line-height: 36px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-about-box-layout2 .item-title {
    font-size: 22px;
    line-height: 34px;
  }
}
.fxt-about-box-layout2 .item-subtitle {
  color: var(--bs-white-color-text-theme);
  margin-bottom: 20px;
}
.fxt-about-box-layout2 .personal-info .info-list ul li {
  color: var(--bs-text-secondary-white);
  font-size: 14px;
  margin-bottom: 5px;
}
.fxt-about-box-layout2 .personal-info .info-list ul li:last-child {
  margin-bottom: 0;
}
.fxt-about-box-layout2 .personal-info .info-list ul li span {
  font-weight: 600;
  color: var(--bs-white-color-text-theme);
  min-width: 130px;
  display: inline-block;
}
/*=======================================================================
07. Skill Section 
=========================================================================*/
.fxt-skill-section {
  background-color: var(--bs-bg-color-theme-secondary-2);
  position: relative;
  z-index: 1;
}
.fxt-skill-section:after {
  content: "";
  height: 100%;
  width: 50%;
  background-color: var(--bs-bg-color-theme-secondary);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
@media only screen and (max-width: 991px) {
  .fxt-skill-section:after {
    display: none;
  }
}
.fxt-skill-wrap-layout1 {
  margin-right: 100px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 991px) {
  .fxt-skill-wrap-layout1 {
    margin-right: 0;
    margin-bottom: 50px;
  }
}
.fxt-skill-wrap-layout1 .item-heading {
  margin-bottom: 30px;
}
.fxt-skill-wrap-layout1 .item-heading .item-title {
  font-size: 28px;
  line-height: 40px;
  font-weight: 700;
  margin-bottom: 5px;
}
@media only screen and (max-width: 991px) {
  .fxt-skill-wrap-layout1 .item-heading .item-title {
    font-size: 26px;
    line-height: 38px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-skill-wrap-layout1 .item-heading .item-title {
    font-size: 24px;
    line-height: 36px;
  }
}
@media only screen and (max-width: 575px) {
  .fxt-skill-wrap-layout1 .item-heading .item-title {
    font-size: 22px;
    line-height: 34px;
  }
}
.fxt-skill-wrap-layout1 .item-heading p {
  color: var(--bs-text-secondary-white);
}
.fxt-skill-box-layout1 {
  overflow: hidden;
}
.fxt-skill-box-layout1 .single-item {
  margin-bottom: 30px;
}
.fxt-skill-box-layout1 .single-item .progress {
  background-color: var(--bs-white-color-bg-theme);
  border-radius: 10px;
  overflow: visible;
  margin-bottom: 10px;
  height: 10px;
}
.fxt-skill-box-layout1 .single-item .progress .progress-bar {
  background-color: var(--bs-default-theme);
  border-radius: 10px;
  overflow: visible;
  position: relative;
}
.fxt-skill-box-layout1 .single-item .progress .progress-bar span {
  font-weight: 600;
  color: var(--bs-text-secondary-white);
  font-size: 16px;
  position: absolute;
  bottom: -38px;
  right: 0;
}
.fxt-skill-box-layout1 .single-item .progress .progress-bar:after {
  content: "";
  height: 23px;
  width: 23px;
  background-color: var(--bs-white-color-bg-theme);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: -2px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.16);
}
.fxt-skill-box-layout1 label {
  font-weight: 600;
  color: var(--bs-text-secondary-white);
}
.fxt-skill-wrap-layout2 {
  margin-left: 100px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 991px) {
  .fxt-skill-wrap-layout2 {
    margin-left: 0;
  }
}
.fxt-skill-wrap-layout2 .item-heading {
  margin-bottom: 30px;
}
.fxt-skill-wrap-layout2 .item-heading .item-title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 1.5;
}
.fxt-skill-wrap-layout2 .item-heading p {
  color: var(--bs-text-secondary-white);
}
.fxt-skill-box-layout2 {
  overflow: hidden;
}
.fxt-skill-box-layout2 .single-item {
  margin-bottom: 30px;
}
.fxt-skill-box-layout2 .single-item .progress {
  background-color: var(--bs-bg-transparent);
  border-radius: 10px;
  overflow: visible;
  margin-bottom: 10px;
  height: 10px;
  border: 1px solid var(--bs-input-border-color);
  padding: 2px;
}
.fxt-skill-box-layout2 .single-item .progress .progress-bar {
  height: 4px;
  background-color: var(--bs-default-theme);
  border-radius: 10px;
  overflow: visible;
  position: relative;
}
.fxt-skill-box-layout2 .single-item .progress .progress-bar span {
  font-weight: 600;
  color: var(--bs-text-secondary-white);
  font-size: 16px;
  position: absolute;
  bottom: -38px;
  right: 0;
}
.fxt-skill-box-layout2 .single-item .progress .progress-bar:after {
  content: "";
  height: 23px;
  width: 23px;
  background-color: var(--bs-white-color-bg-theme);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: -2px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.16);
}
.fxt-skill-box-layout2 label {
  font-weight: 600;
  color: var(--bs-text-secondary-white);
}
/*=======================================================================
08. Service Section 
=========================================================================*/
@media only screen and (max-width: 575px) {
  .fxt-service-section {
    padding-bottom: 53px;
  }
}
.fxt-service-box-layout1 {
  box-shadow: 0px 0px 62px 0px rgba(0, 0, 0, 0.06);
  text-align: center;
  padding: 50px 40px;
  margin-bottom: 30px;
  background-color: var(--bs-cart-bg-color-theme);
}
@media only screen and (max-width: 767px) {
  .fxt-service-box-layout1 {
    padding: 30px 15px;
  }
}
@media only screen and (max-width: 575px) {
  .fxt-service-box-layout1 {
    padding: 35px 25px;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 479px) {
  .fxt-service-box-layout1 {
    padding: 25px 15px;
  }
}
.fxt-service-box-layout1 .item-img {
  margin-bottom: 15px;
  animation: up-down 15s infinite;
}
.fxt-service-box-layout1 .item-img.animation-delay0 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.fxt-service-box-layout1 .item-img.animation-delay1 {
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}
.fxt-service-box-layout1 .item-img.animation-delay2 {
  -webkit-animation-delay: 0.55s;
  animation-delay: 0.55s;
}
.fxt-service-box-layout1 .item-img.animation-delay3 {
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}
.fxt-service-box-layout1 .item-img.animation-delay4 {
  -webkit-animation-delay: 0.95s;
  animation-delay: 0.95s;
}
.fxt-service-box-layout1 .item-img.animation-delay5 {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}
.fxt-service-box-layout1 .item-content .item-title {
  font-weight: 600;
  margin-bottom: 6px;
}
.fxt-service-box-layout1 .item-content .item-title a {
  color: var(--bs-white-color-text-theme);
  transition: all 0.3s ease-in-out;
}
.fxt-service-box-layout1 .item-content .item-title a:hover {
  color: var(--bs-default-theme);
}
.fxt-service-box-layout1 .item-content a {
  font-weight: 600;
  color: var(--bs-text-secondary-white);
  transition: all 0.3s ease-in-out;
}
.fxt-service-box-layout1 .item-content a:hover {
  color: var(--bs-default-theme);
}
@keyframes up-down {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  20% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  30% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  40% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  70% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  90% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
/*=======================================================================
09. Testimonial Section 
=========================================================================*/
.fxt-testimonial-section {
  position: relative;
}
.fxt-testimonial-section:before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(12, 17, 41, 0.85);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.fxt-testimonial-box-layout1 {
  margin-bottom: 30px;
}
@media only screen and (max-width: 991px) {
  .fxt-testimonial-box-layout1 {
    text-align: center;
  }
}
.fxt-testimonial-box-layout1 .item-icon {
  margin-bottom: 25px;
  line-height: 1.1;
}
.fxt-testimonial-box-layout1 .item-icon i:before {
  font-size: 64px;
  color: var(--bs-default-theme);
}
.fxt-testimonial-box-layout1 .item-text {
  font-size: 18px;
  font-weight: 600;
  color: #dad9d9;
  font-style: italic;
  line-height: 32px;
  margin-bottom: 20px;
}
.fxt-testimonial-box-layout1 .item-name {
  font-size: 18px;
  color: var(--bs-default-theme);
}
.fxt-testimonial-box-layout1 .item-name span {
  font-weight: 300;
  color: #dad9d9;
}
/*=======================================================================
10. Portfolio Section
=========================================================================*/
.fxt-portfolio-section {
  overflow: hidden;
}
@media only screen and (max-width: 575px) {
  .fxt-portfolio-section {
    padding-bottom: 53px;
  }
}
.fxt-portfolio-box-layout1 {
  margin-bottom: 30px;
  position: relative;
}
@media only screen and (max-width: 575px) {
  .fxt-portfolio-box-layout1 {
    margin-bottom: 15px;
  }
}
.fxt-portfolio-box-layout1:after {
  content: "";
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  background-color: rgba(27, 33, 64, 0.9);
  position: absolute;
  top: 10px;
  left: 10px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.fxt-portfolio-box-layout1 .item-img .item-content {
  position: absolute;
  bottom: 35px;
  left: 40px;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
@media only screen and (max-width: 575px) {
  .fxt-portfolio-box-layout1 .item-img .item-content {
    left: 20px;
  }
}
.fxt-portfolio-box-layout1 .item-img .item-content .item-icon {
  font-size: 24px;
  margin-bottom: 16px;
}
.fxt-portfolio-box-layout1 .item-img .item-content .item-icon a {
  color: var(--bs-white-color-text-theme);
  transition: all 0.3s ease-in-out;
}
.fxt-portfolio-box-layout1 .item-img .item-content .item-icon a:hover {
  color: var(--bs-default-theme);
}
.fxt-portfolio-box-layout1 .item-img .item-content .item-title {
  font-weight: 600;
  margin-bottom: 0;
}
.fxt-portfolio-box-layout1 .item-img .item-content .item-title a {
  color: var(--bs-white-color-text-theme);
  transition: all 0.3s ease-in-out;
}
.fxt-portfolio-box-layout1 .item-img .item-content .item-title a:hover {
  color: var(--bs-default-theme);
}
.fxt-portfolio-box-layout1 .item-img .item-content .item-paragraph {
  font-size: 15px;
  color: var(--bs-white-color-text-theme);
  font-weight: 300;
}
.fxt-portfolio-box-layout1:hover:after {
  visibility: visible;
  opacity: 1;
}
.fxt-portfolio-box-layout1:hover .item-img .item-content {
  visibility: visible;
  opacity: 1;
  transition-delay: 0.1s;
}
.fxt-portfolio-detail-layout1 {
  padding-top: 100px;
  box-shadow: 0px 0px 57px 0px rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 991px) {
  .fxt-portfolio-detail-layout1 {
    padding-top: 90px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-portfolio-detail-layout1 {
    padding-top: 80px;
  }
}
@media only screen and (max-width: 575px) {
  .fxt-portfolio-detail-layout1 {
    padding-top: 70px;
  }
}
.fxt-portfolio-detail-layout1 .item-gallery .item-img {
  margin-bottom: 40px;
}
@media only screen and (max-width: 767px) {
  .fxt-portfolio-detail-layout1 .item-gallery .item-img {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .fxt-portfolio-detail-layout1 .item-gallery .item-img {
    margin-bottom: 15px;
  }
}
.fxt-portfolio-detail-layout1 .item-content .item-left-content {
  margin-bottom: 20px;
}
.fxt-portfolio-detail-layout1 .item-content .item-left-content .item-title {
  font-weight: 700;
  margin-bottom: 12px;
}
.fxt-portfolio-detail-layout1 .item-content .item-left-content ul li {
  color: var(--bs-white-secondary-theme);
  margin-bottom: 4px;
}
.fxt-portfolio-detail-layout1 .item-content .item-left-content ul li:last-child {
  margin-bottom: 0;
}
.fxt-portfolio-detail-layout1 .item-content .item-right-content {
  margin-bottom: 20px;
}
.fxt-portfolio-detail-layout1 .item-content .item-right-content .item-title {
  font-weight: 700;
}
/*=======================================================================
11. Progress Section
=========================================================================*/
.fxt-progress-section {
  position: relative;
  z-index: 1;
  padding-bottom: 60px;
}
@media only screen and (max-width: 991px) {
  .fxt-progress-section {
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-progress-section {
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 575px) {
  .fxt-progress-section {
    padding-bottom: 30px;
  }
}
.fxt-progress-section:after {
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(12, 17, 41, 0.4);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
.fxt-progress-box-layout1 {
  text-align: center;
  margin-bottom: 30px;
}
.fxt-progress-box-layout1 .counting-text {
  font-weight: 800;
  color: var(--bs-white-color-text-theme);
  margin-bottom: 0;
}
.fxt-progress-box-layout1 .item-label {
  color: var(--bs-white-color-text-theme);
  font-weight: 600;
}
/*=======================================================================
12. Pricing Section
=========================================================================*/
@media only screen and (max-width: 575px) {
  .fxt-pricing-section {
    padding-bottom: 53px;
  }
}
.fxt-pricing-box-layout1 {
  background-color: var(--bs-cart-bg-color-theme);
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.03);
  text-align: center;
  padding: 40px 0 50px 0;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
}
@media only screen and (max-width: 575px) {
  .fxt-pricing-box-layout1 {
    margin-bottom: 15px;
  }
}
.fxt-pricing-box-layout1 .pricing-amount {
  margin-bottom: 20px;
  padding: 0 30px;
}
@media only screen and (max-width: 575px) {
  .fxt-pricing-box-layout1 .pricing-amount {
    padding: 0 15px;
  }
}
.fxt-pricing-box-layout1 .pricing-amount .item-price {
  font-size: 34px;
  font-weight: 700;
  color: var(--bs-default-theme);
  padding-bottom: 34px;
  border-bottom: 1px solid rgba(133, 149, 233, 0.09);
  transition: all 0.3s ease-in-out;
}
.fxt-pricing-box-layout1 .pricing-amount .item-price span {
  font-size: 16px;
  font-weight: 400;
  color: var(--bs-text-secondary-white);
}
@media only screen and (max-width: 991px) {
  .fxt-pricing-box-layout1 .pricing-amount .item-price {
    font-size: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-pricing-box-layout1 .pricing-amount .item-price {
    font-size: 28px;
  }
}
@media only screen and (max-width: 575px) {
  .fxt-pricing-box-layout1 .pricing-amount .item-price {
    font-size: 26px;
  }
}
.fxt-pricing-box-layout1 .pricing-title {
  padding: 0 30px;
}
@media only screen and (max-width: 575px) {
  .fxt-pricing-box-layout1 .pricing-title {
    padding: 0 15px;
  }
}
.fxt-pricing-box-layout1 .pricing-title .item-title {
  font-weight: 700;
  margin-bottom: 5px;
}
.fxt-pricing-box-layout1 .features-list {
  margin: 35px 0 45px;
  padding: 0 30px;
}
@media only screen and (max-width: 575px) {
  .fxt-pricing-box-layout1 .features-list {
    padding: 0 15px;
  }
}
.fxt-pricing-box-layout1 .features-list ul li {
  margin-bottom: 10px;
}
.fxt-pricing-box-layout1 .features-list ul li:last-child {
  margin-bottom: 0;
}
.fxt-pricing-box-layout1 .item-btn {
  padding: 10px 40px;
  font-weight: 600;
}
.fxt-pricing-box-layout1:hover {
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
}
.fxt-pricing-box-layout1:hover .pricing-amount .item-price {
  border-bottom: 1px solid var(--bs-default-theme);
}
/*=======================================================================
13. Blog Section 
=========================================================================*/
@media only screen and (max-width: 575px) {
  .fxt-blog-section {
    padding-bottom: 53px;
  }
}
.entry-meta {
  margin-bottom: 10px;
}
.entry-meta ul li {
  display: inline-block;
  color: var(--bs-text-secondary-white);
  font-size: 14px;
  margin-right: 20px;
}
.entry-meta ul li:last-child {
  margin-right: 0;
}
.entry-meta ul li i {
  color: var(--bs-default-theme);
  font-size: 15px;
  margin-right: 8px;
}
.entry-meta ul li a {
  color: var(--bs-text-secondary-white);
  transition: all 0.3s ease-in-out;
}
.entry-meta ul li a:hover {
  color: var(--bs-default-theme);
}
.fxt-blog-box-layout1 {
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
}
@media only screen and (max-width: 575px) {
  .fxt-blog-box-layout1 {
    margin-bottom: 15px;
  }
}
.fxt-blog-box-layout1 .item-img {
  background: transparent;
  overflow: hidden;
  position: relative;
}
.fxt-blog-box-layout1 .item-img:before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
.fxt-blog-box-layout1 .item-img:hover:before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}
.fxt-blog-box-layout1 .item-content {
  background-color: var(--bs-cart-bg-color-theme);
  padding: 30px;
}
@media only screen and (max-width: 575px) {
  .fxt-blog-box-layout1 .item-content {
    padding: 15px;
  }
}
.fxt-blog-box-layout1 .item-content .item-title {
  font-weight: 700;
  margin-bottom: 14px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.fxt-blog-box-layout1 .item-content .item-title a {
  color: var(--bs-white-color-text-theme);
  transition: all 0.3s ease-in-out;
}
.fxt-blog-box-layout1 .item-content .item-title a:hover {
  color: var(--bs-default-theme);
}
.fxt-blog-box-layout1 .item-content p {
  margin-bottom: 5px;
}
.fxt-blog-box-layout1:hover {
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.07);
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
.fxt-blog-detail-wrap-layout1 {
  padding-top: 100px;
  box-shadow: 0px 0px 57px 0px rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 991px) {
  .fxt-blog-detail-wrap-layout1 {
    padding-top: 90px;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-blog-detail-wrap-layout1 {
    padding-top: 80px;
  }
}
@media only screen and (max-width: 575px) {
  .fxt-blog-detail-wrap-layout1 {
    padding-top: 70px;
  }
}
.fxt-blog-detail-box-layout1 {
  margin-bottom: 20px;
}
.fxt-blog-detail-box-layout1 .item-img {
  margin-bottom: 52px;
}
.fxt-blog-detail-box-layout1 .item-content .item-title {
  font-weight: 700;
  margin-bottom: 14px;
}
.fxt-blog-detail-box-layout1 .item-content p {
  margin-bottom: 30px;
}
.fxt-blog-detail-box-layout1 .item-content .item-gallery-wrap {
  margin-bottom: 10px;
}
.fxt-blog-detail-box-layout1 .item-content .item-gallery-wrap .item-gallery-box {
  margin-bottom: 15px;
}
.fxt-blog-detail-box-layout1 .item-content .item-social li {
  display: inline-block;
}
.fxt-blog-detail-box-layout1 .item-content .item-social li:last-child a:after {
  display: none;
}
.fxt-blog-detail-box-layout1 .item-content .item-social li a {
  font-weight: 600;
  color: var(--bs-white-color-text-theme);
  transition: all 0.3s ease-in-out;
}
.fxt-blog-detail-box-layout1 .item-content .item-social li a:after {
  content: "-";
  color: var(--bs-white-color-text-theme);
  font-size: 16px;
  padding: 0 14px;
}
.fxt-blog-detail-box-layout1 .item-content .item-social li a:hover {
  color: var(--bs-default-theme);
}
/*=======================================================================
14. Contact Section 
=========================================================================*/
.fxt-contact-section {
  background-repeat: no-repeat;
  background-position: center bottom;
}
.fxt-contact-box-layout1 {
  background-color: var(--bs-cart-bg-color-theme);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  padding: 70px 50px 60px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .fxt-contact-box-layout1 {
    padding: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .fxt-contact-box-layout1 {
    padding: 15px;
  }
}
.fxt-contact-box-layout1 .contact-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 34px;
}
@media only screen and (max-width: 575px) {
  .fxt-contact-box-layout1 .contact-list {
    display: block;
  }
}
.fxt-contact-box-layout1 .contact-list:last-child {
  margin-bottom: 0;
}
.fxt-contact-box-layout1 .contact-list .item-icon {
  margin-right: 15px;
  margin-top: 10px;
}
@media only screen and (max-width: 575px) {
  .fxt-contact-box-layout1 .contact-list .item-icon {
    margin-bottom: 10px;
  }
}
.fxt-contact-box-layout1 .contact-list .item-icon i:before {
  font-size: 40px;
  color: var(--bs-default-theme);
}
.fxt-contact-box-layout1 .contact-list .item-content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.fxt-contact-box-layout1 .contact-list .item-content .item-title {
  font-weight: 700;
  margin-bottom: 0;
}
.fxt-contact-box-layout1 .contact-list .item-content .item-text {
  color: var(--bs-white-color-text-theme);
}
.fxt-contact-box-layout2 {
  margin-bottom: 30px;
}
.fxt-contact-box-layout2 form .form-group {
  margin-bottom: 30px;
}
.fxt-contact-box-layout2 form .form-group:last-child {
  margin-bottom: 0;
}
.fxt-contact-box-layout2 form .form-group .form-control {
  border: 1px solid var(--bs-input-border-color);
  border-radius: 0;
  background-color: var(--bs-bg-transparent);
  padding: 10px 20px;
  font-size: 15px;
}
.fxt-contact-box-layout2 form .form-group .form-control:focus {
  outline: none;
  box-shadow: none;
}
.fxt-contact-box-layout2 form .form-group input {
  height: 50px;
  color: var(--bs-white-color-text-theme);
}
.fxt-contact-box-layout2 form .form-group textarea {
  color: var(--bs-white-color-text-theme);
}
.fxt-contact-box-layout2 form .form-group input::placeholder,
.fxt-contact-box-layout2 form .form-group textarea::placeholder {
  font-size: 15px;
  color: #565967;
}
.fxt-contact-box-layout2 form .form-group .submit-btn {
  cursor: pointer;
  padding: 9px 40px;
  transition: all 0.3s ease-in-out;
}
.fxt-contact-box-layout2 form .form-group .submit-btn:focus {
  outline: 0;
}
/*=======================================================================
15. Footer 
=========================================================================*/
.fxt-footer-section {
  padding: 15px 0px;
}
.fxt-footer-box-layout1 {
  text-align: center;
}
.fxt-footer-box-layout1 .copyright-text {
  font-size: 15px;
  color: #565967;
}
.fxt-footer-box-layout1 .copyright-text a {
  color: #565967;
  text-decoration: underline;
  transition: all 0.3s ease-in-out;
}
.fxt-footer-box-layout1 .copyright-text a:hover {
  color: var(--bs-default-theme);
}
/*=======================================================================
16. Sidebar 
=========================================================================*/
@media only screen and (max-width: 991px) {
  .sidebar-break-md {
    margin-top: 50px;
  }
}
.sidebar-widget-area {
  margin-bottom: 30px;
}
.sidebar-widget-area .widget {
  margin-bottom: 40px;
}
.sidebar-widget-area .widget:last-child {
  margin-bottom: 0 !important;
}
.widget-bg {
  background-color: #090d24;
  padding: 24px 30px 32px 30px;
}
.widget-title {
  font-weight: 600;
  margin-bottom: 15px;
}
.widget-author {
  text-align: center;
  padding: 50px 30px 22px 30px;
}
.widget-author .item-img {
  margin-bottom: 18px;
}
.widget-author .item-img img {
  border-radius: 50%;
}
.widget-author .item-content .item-social {
  margin-bottom: 16px;
}
.widget-author .item-content .item-social .social-title {
  color: var(--bs-text-secondary-white);
  display: block;
  margin-bottom: 5px;
}
.widget-author .item-content .item-social .social-icon a {
  color: var(--bs-text-secondary-white);
  font-size: 18px;
  padding: 5px;
  margin-right: 8px;
  transition: all 0.3s ease-in-out;
}
.widget-author .item-content .item-social .social-icon a:last-child {
  margin-right: 0;
}
.widget-author .item-content .item-social .social-icon a:hover {
  color: var(--bs-default-theme);
}
.widget-author .item-content .item-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 5px;
}
@media only screen and (max-width: 767px) {
  .widget-author .item-content .item-title {
    font-size: 18px;
    line-height: 28px;
  }
}
.widget-category .category-list li {
  margin-bottom: 20px;
}
.widget-category .category-list li:last-child {
  margin-bottom: 0;
}
.widget-category .category-list li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 18px;
  color: var(--bs-text-secondary-white);
  transition: all 0.3s ease-in-out;
}
.widget-category .category-list li a:hover {
  color: var(--bs-default-theme);
}
.widget-tag .tag-list li {
  display: inline-block;
  margin-bottom: 15px;
  margin-right: 10px;
}
.widget-tag .tag-list li a {
  color: var(--bs-text-secondary-white);
  display: inline-block;
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 4px;
  padding: 4px 22px;
  transition: all 0.3s ease-in-out;
}
.widget-tag .tag-list li a:hover {
  color: var(--bs-white-color-text-theme);
  background-color: var(--bs-default-theme);
  border-color: var(--bs-default-theme);
}
/*=======================================================================
17. Animation Style
=========================================================================*/
body {
  position: relative;
  z-index: 99;
}
body .wrapper {
  opacity: 0;
  visibility: hidden;
}
body .preloader {
  position: absolute;
  z-index: 9999999;
  height: 100%;
  width: 100%;
}
body .preloader-custom-style {
  height: 100%;
  width: 100%;
  position: relative;
}
body .preloader-custom-style:after,
body .preloader-custom-style:before {
  position: absolute;
  z-index: 99;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  visibility: visible;
  background-color: var(--bs-white-color-bg-theme);
}
body .preloader-gif {
  background-color: var(--bs-white-color-bg-theme);
  height: 100%;
  width: 100%;
}
body .preloader-gif img {
  position: fixed;
  z-index: 999;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}
body .has-animation .opacity-animation {
  opacity: 0;
  visibility: hidden;
}
body .has-animation .translate-zoomout-10 {
  -webkit-transform: scale(0.1);
  -ms-transform: scale(0.1);
  transform: scale(0.1);
}
body .has-animation .translate-zoomout-20 {
  -webkit-transform: scale(0.2);
  -ms-transform: scale(0.2);
  transform: scale(0.2);
}
body .has-animation .translate-zoomout-30 {
  -webkit-transform: scale(0.3);
  -ms-transform: scale(0.3);
  transform: scale(0.3);
}
body .has-animation .translate-zoomout-40 {
  -webkit-transform: scale(0.4);
  -ms-transform: scale(0.4);
  transform: scale(0.4);
}
body .has-animation .translate-zoomout-50 {
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
}
body .has-animation .translate-left-10 {
  -webkit-transform: translateX(-10%);
  -ms-transform: translateX(-10%);
  transform: translateX(-10%);
}
body .has-animation .translate-left-20 {
  -webkit-transform: translateX(-20%);
  -ms-transform: translateX(-20%);
  transform: translateX(-20%);
}
body .has-animation .translate-left-30 {
  -webkit-transform: translateX(-30%);
  -ms-transform: translateX(-30%);
  transform: translateX(-30%);
}
body .has-animation .translate-left-40 {
  -webkit-transform: translateX(-40%);
  -ms-transform: translateX(-40%);
  transform: translateX(-40%);
}
body .has-animation .translate-left-50 {
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
body .has-animation .translate-left-75 {
  -webkit-transform: translateX(-75%);
  -ms-transform: translateX(-75%);
  transform: translateX(-75%);
}
body .has-animation .translate-left-100 {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}
body .has-animation .translate-right-10 {
  -webkit-transform: translateX(10%);
  -ms-transform: translateX(10%);
  transform: translateX(10%);
}
body .has-animation .translate-right-20 {
  -webkit-transform: translateX(20%);
  -ms-transform: translateX(20%);
  transform: translateX(20%);
}
body .has-animation .translate-right-30 {
  -webkit-transform: translateX(30%);
  -ms-transform: translateX(30%);
  transform: translateX(30%);
}
body .has-animation .translate-right-40 {
  -webkit-transform: translateX(40%);
  -ms-transform: translateX(40%);
  transform: translateX(40%);
}
body .has-animation .translate-right-50 {
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}
body .has-animation .translate-right-75 {
  -webkit-transform: translateX(75%);
  -ms-transform: translateX(75%);
  transform: translateX(75%);
}
body .has-animation .translate-right-100 {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}
body .has-animation .translate-top-10 {
  -webkit-transform: translateY(-10%);
  -ms-transform: translateY(-10%);
  transform: translateY(-10%);
}
body .has-animation .translate-top-20 {
  -webkit-transform: translateY(-20%);
  -ms-transform: translateY(-20%);
  transform: translateY(-20%);
}
body .has-animation .translate-top-30 {
  -webkit-transform: translateY(-30%);
  -ms-transform: translateY(-30%);
  transform: translateY(-30%);
}
body .has-animation .translate-top-40 {
  -webkit-transform: translateY(-40%);
  -ms-transform: translateY(-40%);
  transform: translateY(-40%);
}
body .has-animation .translate-top-50 {
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
body .has-animation .translate-top-75 {
  -webkit-transform: translateY(-75%);
  -ms-transform: translateY(-75%);
  transform: translateY(-75%);
}
body .has-animation .translate-top-100 {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}
body .has-animation .translate-bottom-10 {
  -webkit-transform: translateY(10%);
  -ms-transform: translateY(10%);
  transform: translateY(10%);
}
body .has-animation .translate-bottom-20 {
  -webkit-transform: translateY(20%);
  -ms-transform: translateY(20%);
  transform: translateY(20%);
}
body .has-animation .translate-bottom-30 {
  -webkit-transform: translateY(30%);
  -ms-transform: translateY(30%);
  transform: translateY(30%);
}
body .has-animation .translate-bottom-40 {
  -webkit-transform: translateY(40%);
  -ms-transform: translateY(40%);
  transform: translateY(40%);
}
body .has-animation .translate-bottom-50 {
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}
body .has-animation .translate-bottom-75 {
  -webkit-transform: translateY(75%);
  -ms-transform: translateY(75%);
  transform: translateY(75%);
}
body .has-animation .translate-bottom-100 {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}
body .has-animation .translate-bottom-left-10 {
  -webkit-transform: translateY(10%) translateX(-10%);
  -ms-transform: translateY(10%) translateX(-10%);
  transform: translateY(10%) translateX(-10%);
}
body .has-animation .translate-bottom-left-20 {
  -webkit-transform: translateY(20%) translateX(-20%);
  -ms-transform: translateY(20%) translateX(-20%);
  transform: translateY(20%) translateX(-20%);
}
body .has-animation .translate-bottom-left-30 {
  -webkit-transform: translateY(30%) translateX(-30%);
  -ms-transform: translateY(30%) translateX(-30%);
  transform: translateY(30%) translateX(-30%);
}
body .has-animation .translate-bottom-left-40 {
  -webkit-transform: translateY(40%) translateX(-40%);
  -ms-transform: translateY(40%) translateX(-40%);
  transform: translateY(40%) translateX(-40%);
}
body .has-animation .translate-bottom-left-50 {
  -webkit-transform: translateY(50%) translateX(-50%);
  -ms-transform: translateY(50%) translateX(-50%);
  transform: translateY(50%) translateX(-50%);
}
body .has-animation .translate-bottom-left-75 {
  -webkit-transform: translateY(75%) translateX(-75%);
  -ms-transform: translateY(75%) translateX(-75%);
  transform: translateY(75%) translateX(-75%);
}
body .has-animation .translate-bottom-left-100 {
  -webkit-transform: translateY(100%) translateX(-100%);
  -ms-transform: translateY(100%) translateX(-100%);
  transform: translateY(100%) translateX(-100%);
}
body .has-animation .translate-top-left-10 {
  -webkit-transform: translateY(-10%) translateX(-10%);
  -ms-transform: translateY(-10%) translateX(-10%);
  transform: translateY(-10%) translateX(-10%);
}
body .has-animation .translate-top-left-20 {
  -webkit-transform: translateY(-20%) translateX(-20%);
  -ms-transform: translateY(-20%) translateX(-20%);
  transform: translateY(-20%) translateX(-20%);
}
body .has-animation .translate-top-left-30 {
  -webkit-transform: translateY(-30%) translateX(-30%);
  -ms-transform: translateY(-30%) translateX(-30%);
  transform: translateY(-30%) translateX(-30%);
}
body .has-animation .translate-top-left-40 {
  -webkit-transform: translateY(-40%) translateX(-40%);
  -ms-transform: translateY(-40%) translateX(-40%);
  transform: translateY(-40%) translateX(-40%);
}
body .has-animation .translate-top-left-50 {
  -webkit-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}
body .has-animation .translate-top-left-75 {
  -webkit-transform: translateY(-75%) translateX(-75%);
  -ms-transform: translateY(-75%) translateX(-75%);
  transform: translateY(-75%) translateX(-75%);
}
body .has-animation .translate-top-left-100 {
  -webkit-transform: translateY(-100%) translateX(-100%);
  -ms-transform: translateY(-100%) translateX(-100%);
  transform: translateY(-100%) translateX(-100%);
}
body .has-animation .translate-top-right-10 {
  -webkit-transform: translateY(-10%) translateX(10%);
  -ms-transform: translateY(-10%) translateX(10%);
  transform: translateY(-10%) translateX(10%);
}
body .has-animation .translate-top-right-20 {
  -webkit-transform: translateY(-20%) translateX(20%);
  -ms-transform: translateY(-20%) translateX(20%);
  transform: translateY(-20%) translateX(20%);
}
body .has-animation .translate-top-right-30 {
  -webkit-transform: translateY(-30%) translateX(30%);
  -ms-transform: translateY(-30%) translateX(30%);
  transform: translateY(-30%) translateX(30%);
}
body .has-animation .translate-top-right-40 {
  -webkit-transform: translateY(-40%) translateX(40%);
  -ms-transform: translateY(-40%) translateX(40%);
  transform: translateY(-40%) translateX(40%);
}
body .has-animation .translate-top-right-50 {
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
body .has-animation .translate-top-right-75 {
  -webkit-transform: translateY(-75%) translateX(75%);
  -ms-transform: translateY(-75%) translateX(75%);
  transform: translateY(-75%) translateX(75%);
}
body .has-animation .translate-top-right-100 {
  -webkit-transform: translateY(-100%) translateX(100%);
  -ms-transform: translateY(-100%) translateX(100%);
  transform: translateY(-100%) translateX(100%);
}
body .transition-10,
body .transition-20,
body .transition-30,
body .transition-40,
body .transition-50,
body .transition-100,
body .transition-150,
body .transition-200,
body .transition-250,
body .transition-300,
body .transition-350,
body .transition-400,
body .transition-450,
body .transition-500,
body .transition-550,
body .transition-600,
body .transition-650,
body .transition-700,
body .transition-750,
body .transition-800,
body .transition-850,
body .transition-900,
body .transition-950,
body .transition-1000 {
  transition: all 0.01s ease-out;
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
body.loaded .wrapper {
  opacity: 1;
  visibility: visible;
}
body.loaded .preloader {
  z-index: -99999;
}
body.loaded .preloader-custom-style:after,
body.loaded .preloader-custom-style:before {
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}
body.loaded .preloader-gif {
  display: none;
}
body.loaded .has-animation.active-animation .opacity-animation {
  opacity: 1!important;
  visibility: visible!important;
}
body.loaded .has-animation.active-animation .translate-zoomout-10,
body.loaded .has-animation.active-animation .translate-zoomout-20,
body.loaded .has-animation.active-animation .translate-zoomout-30,
body.loaded .has-animation.active-animation .translate-zoomout-40,
body.loaded .has-animation.active-animation .translate-zoomout-50 {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
body.loaded .has-animation.active-animation .translate-left-10,
body.loaded .has-animation.active-animation .translate-left-20,
body.loaded .has-animation.active-animation .translate-left-30,
body.loaded .has-animation.active-animation .translate-left-40,
body.loaded .has-animation.active-animation .translate-left-50,
body.loaded .has-animation.active-animation .translate-left-75,
body.loaded .has-animation.active-animation .translate-left-100,
body.loaded .has-animation.active-animation .translate-right-10,
body.loaded .has-animation.active-animation .translate-right-20,
body.loaded .has-animation.active-animation .translate-right-30,
body.loaded .has-animation.active-animation .translate-right-40,
body.loaded .has-animation.active-animation .translate-right-50,
body.loaded .has-animation.active-animation .translate-right-75,
body.loaded .has-animation.active-animation .translate-right-100,
body.loaded .has-animation.active-animation .translate-top-10,
body.loaded .has-animation.active-animation .translate-top-20,
body.loaded .has-animation.active-animation .translate-top-30,
body.loaded .has-animation.active-animation .translate-top-40,
body.loaded .has-animation.active-animation .translate-top-50,
body.loaded .has-animation.active-animation .translate-top-75,
body.loaded .has-animation.active-animation .translate-top-100,
body.loaded .has-animation.active-animation .translate-bottom-10,
body.loaded .has-animation.active-animation .translate-bottom-20,
body.loaded .has-animation.active-animation .translate-bottom-30,
body.loaded .has-animation.active-animation .translate-bottom-40,
body.loaded .has-animation.active-animation .translate-bottom-50,
body.loaded .has-animation.active-animation .translate-bottom-75,
body.loaded .has-animation.active-animation .translate-bottom-100,
body.loaded .has-animation.active-animation .translate-top-left-10,
body.loaded .has-animation.active-animation .translate-top-left-20,
body.loaded .has-animation.active-animation .translate-top-left-30,
body.loaded .has-animation.active-animation .translate-top-left-40,
body.loaded .has-animation.active-animation .translate-top-left-50,
body.loaded .has-animation.active-animation .translate-top-left-75,
body.loaded .has-animation.active-animation .translate-top-left-100,
body.loaded .has-animation.active-animation .translate-top-right-10,
body.loaded .has-animation.active-animation .translate-top-right-20,
body.loaded .has-animation.active-animation .translate-top-right-30,
body.loaded .has-animation.active-animation .translate-top-right-40,
body.loaded .has-animation.active-animation .translate-top-right-50,
body.loaded .has-animation.active-animation .translate-top-right-75,
body.loaded .has-animation.active-animation .translate-top-right-100,
body.loaded .has-animation.active-animation .translate-bottom-left-10,
body.loaded .has-animation.active-animation .translate-bottom-left-20,
body.loaded .has-animation.active-animation .translate-bottom-left-30,
body.loaded .has-animation.active-animation .translate-bottom-left-40,
body.loaded .has-animation.active-animation .translate-bottom-left-50,
body.loaded .has-animation.active-animation .translate-bottom-left-75,
body.loaded .has-animation.active-animation .translate-bottom-left-100 {
  -webkit-transform: translateY(0) translateX(0);
  -ms-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}
body.loaded .transition-10 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 0.1s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 0.1s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 0.1s, opacity 0.3s;
}
body.loaded .transition-20 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 0.2s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 0.2s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 0.2s, opacity 0.3s;
}
body.loaded .transition-30 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 0.3s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 0.3s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 0.3s, opacity 0.3s;
}
body.loaded .transition-40 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 0.4s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 0.4s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 0.4s, opacity 0.3s;
}
body.loaded .transition-50 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 0.5s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 0.5s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 0.5s, opacity 0.3s;
}
body.loaded .transition-100 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 1s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 1s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 1s, opacity 0.3s;
}
body.loaded .transition-150 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 1.5s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 1.5s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 1.5s, opacity 0.3s;
}
body.loaded .transition-200 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 2s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 2s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 2s, opacity 0.3s;
}
body.loaded .transition-250 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 2.5s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 2.5s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 2.5s, opacity 0.3s;
}
body.loaded .transition-300 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 3s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 3s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 3s, opacity 0.3s;
}
body.loaded .transition-350 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 3.5s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 3.5s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 3.5s, opacity 0.3s;
}
body.loaded .transition-400 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 4s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 4s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 4s, opacity 0.3s;
}
body.loaded .transition-450 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 4.5s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 4.5s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 4.5s, opacity 0.3s;
}
body.loaded .transition-500 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 5s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 5s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 5s, opacity 0.3s;
}
body.loaded .transition-550 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 5.5s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 5.5s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 5.5s, opacity 0.3s;
}
body.loaded .transition-600 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 6s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 6s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 6s, opacity 0.3s;
}
body.loaded .transition-650 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 6.5s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 6.5s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 6.5s, opacity 0.3s;
}
body.loaded .transition-700 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 7s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 7s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 7s, opacity 0.3s;
}
body.loaded .transition-750 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 7.5s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 7.5s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 7.5s, opacity 0.3s;
}
body.loaded .transition-800 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 8s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 8s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 8s, opacity 0.3s;
}
body.loaded .transition-850 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 8.5s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 8.5s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 8.5s, opacity 0.3s;
}
body.loaded .transition-900 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 9s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 9s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 9s, opacity 0.3s;
}
body.loaded .transition-950 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 9.5s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 9.5s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 9.5s, opacity 0.3s;
}
body.loaded .transition-1000 {
  -webkit-transition: cubic-bezier(0, 1, 1, 1) 10s, opacity 0.3s;
  -o-transition: cubic-bezier(0, 1, 1, 1) 10s, opacity 0.3s;
  transition: cubic-bezier(0, 1, 1, 1) 10s, opacity 0.3s;
}
body.loaded .transition-delay-0 {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}
body.loaded .transition-delay-10 {
  -webkit-transition-delay: 0.01s;
  -o-transition-delay: 0.01s;
  transition-delay: 0.01s;
}
body.loaded .transition-delay-20 {
  -webkit-transition-delay: 0.02s;
  -o-transition-delay: 0.02s;
  transition-delay: 0.02s;
}
body.loaded .transition-delay-30 {
  -webkit-transition-delay: 0.03s;
  -o-transition-delay: 0.03s;
  transition-delay: 0.03s;
}
body.loaded .transition-delay-40 {
  -webkit-transition-delay: 0.04s;
  -o-transition-delay: 0.04s;
  transition-delay: 0.04s;
}
body.loaded .transition-delay-50 {
  -webkit-transition-delay: 0.05s;
  -o-transition-delay: 0.05s;
  transition-delay: 0.05s;
}
body.loaded .transition-delay-100 {
  -webkit-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
body.loaded .transition-delay-200 {
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
body.loaded .transition-delay-300 {
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
body.loaded .transition-delay-400 {
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
body.loaded .transition-delay-500 {
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
body.loaded .transition-delay-600 {
  -webkit-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
body.loaded .transition-delay-700 {
  -webkit-transition-delay: 0.7s;
  -o-transition-delay: 0.7s;
  transition-delay: 0.7s;
}
body.loaded .transition-delay-800 {
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
}
body.loaded .transition-delay-900 {
  -webkit-transition-delay: 0.9s;
  -o-transition-delay: 0.9s;
  transition-delay: 0.9s;
}
body.loaded .transition-delay-1000 {
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}
body.loaded .transition-delay-1100 {
  -webkit-transition-delay: 1.1s;
  -o-transition-delay: 1.1s;
  transition-delay: 1.1s;
}
body.loaded .transition-delay-1200 {
  -webkit-transition-delay: 1.2s;
  -o-transition-delay: 1.2s;
  transition-delay: 1.2s;
}
body.loaded .transition-delay-1300 {
  -webkit-transition-delay: 1.3s;
  -o-transition-delay: 1.3s;
  transition-delay: 1.3s;
}
body.loaded .transition-delay-1400 {
  -webkit-transition-delay: 1.4s;
  -o-transition-delay: 1.4s;
  transition-delay: 1.4s;
}
body.loaded .transition-delay-1500 {
  -webkit-transition-delay: 1.5s;
  -o-transition-delay: 1.5s;
  transition-delay: 1.5s;
}
body.loaded .transition-delay-1600 {
  -webkit-transition-delay: 1.6s;
  -o-transition-delay: 1.6s;
  transition-delay: 1.6s;
}
body.loaded .transition-delay-1700 {
  -webkit-transition-delay: 1.7s;
  -o-transition-delay: 1.7s;
  transition-delay: 1.7s;
}
body.loaded .transition-delay-1800 {
  -webkit-transition-delay: 1.8s;
  -o-transition-delay: 1.8s;
  transition-delay: 1.8s;
}
body.loaded .transition-delay-1900 {
  -webkit-transition-delay: 1.9s;
  -o-transition-delay: 1.9s;
  transition-delay: 1.9s;
}
body.loaded .transition-delay-2000 {
  -webkit-transition-delay: 2s;
  -o-transition-delay: 2s;
  transition-delay: 2s;
}
body.loaded .transition-delay-2100 {
  -webkit-transition-delay: 2.1s;
  -o-transition-delay: 2.1s;
  transition-delay: 2.1s;
}
body.loaded .transition-delay-2200 {
  -webkit-transition-delay: 2.2s;
  -o-transition-delay: 2.2s;
  transition-delay: 2.2s;
}
body.loaded .transition-delay-2300 {
  -webkit-transition-delay: 2.3s;
  -o-transition-delay: 2.3s;
  transition-delay: 2.3s;
}
body.loaded .transition-delay-2400 {
  -webkit-transition-delay: 2.4s;
  -o-transition-delay: 2.4s;
  transition-delay: 2.4s;
}
body.loaded .transition-delay-2500 {
  -webkit-transition-delay: 2.5s;
  -o-transition-delay: 2.5s;
  transition-delay: 2.5s;
}
body.loaded .transition-delay-2600 {
  -webkit-transition-delay: 2.6s;
  -o-transition-delay: 2.6s;
  transition-delay: 2.6s;
}
body.loaded .transition-delay-2700 {
  -webkit-transition-delay: 2.7s;
  -o-transition-delay: 2.7s;
  transition-delay: 2.7s;
}
body.loaded .transition-delay-2800 {
  -webkit-transition-delay: 2.8s;
  -o-transition-delay: 2.8s;
  transition-delay: 2.8s;
}
body.loaded .transition-delay-2900 {
  -webkit-transition-delay: 2.9s;
  -o-transition-delay: 2.9s;
  transition-delay: 2.9s;
}
body.loaded .transition-delay-3000 {
  -webkit-transition-delay: 3s;
  -o-transition-delay: 3s;
  transition-delay: 3s;
}
body.loaded .transition-delay-3100 {
  -webkit-transition-delay: 3.1s;
  -o-transition-delay: 3.1s;
  transition-delay: 3.1s;
}
body.loaded .transition-delay-3200 {
  -webkit-transition-delay: 3.2s;
  -o-transition-delay: 3.2s;
  transition-delay: 3.2s;
}
body.loaded .transition-delay-3300 {
  -webkit-transition-delay: 3.3s;
  -o-transition-delay: 3.3s;
  transition-delay: 3.3s;
}
body.loaded .transition-delay-3400 {
  -webkit-transition-delay: 3.4s;
  -o-transition-delay: 3.4s;
  transition-delay: 3.4s;
}
body.loaded .transition-delay-3500 {
  -webkit-transition-delay: 3.5s;
  -o-transition-delay: 3.5s;
  transition-delay: 3.5s;
}
body.loaded .transition-delay-3600 {
  -webkit-transition-delay: 3.6s;
  -o-transition-delay: 3.6s;
  transition-delay: 3.6s;
}
body.loaded .transition-delay-3700 {
  -webkit-transition-delay: 3.7s;
  -o-transition-delay: 3.7s;
  transition-delay: 3.7s;
}
body.loaded .transition-delay-3800 {
  -webkit-transition-delay: 3.8s;
  -o-transition-delay: 3.8s;
  transition-delay: 3.8s;
}
body.loaded .transition-delay-3900 {
  -webkit-transition-delay: 3.9s;
  -o-transition-delay: 3.9s;
  transition-delay: 3.9s;
}
body.loaded .transition-delay-4000 {
  -webkit-transition-delay: 4s;
  -o-transition-delay: 4s;
  transition-delay: 4s;
}
body.loaded .transition-delay-4100 {
  -webkit-transition-delay: 4.1s;
  -o-transition-delay: 4.1s;
  transition-delay: 4.1s;
}
body.loaded .transition-delay-4200 {
  -webkit-transition-delay: 4.2s;
  -o-transition-delay: 4.2s;
  transition-delay: 4.2s;
}
body.loaded .transition-delay-4300 {
  -webkit-transition-delay: 4.3s;
  -o-transition-delay: 4.3s;
  transition-delay: 4.3s;
}
body.loaded .transition-delay-4400 {
  -webkit-transition-delay: 4.4s;
  -o-transition-delay: 4.4s;
  transition-delay: 4.4s;
}
body.loaded .transition-delay-4500 {
  -webkit-transition-delay: 4.5s;
  -o-transition-delay: 4.5s;
  transition-delay: 4.5s;
}
body.loaded .transition-delay-4600 {
  -webkit-transition-delay: 4.6s;
  -o-transition-delay: 4.6s;
  transition-delay: 4.6s;
}
body.loaded .transition-delay-4700 {
  -webkit-transition-delay: 4.7s;
  -o-transition-delay: 4.7s;
  transition-delay: 4.7s;
}
body.loaded .transition-delay-4800 {
  -webkit-transition-delay: 4.8s;
  -o-transition-delay: 4.8s;
  transition-delay: 4.8s;
}
body.loaded .transition-delay-4900 {
  -webkit-transition-delay: 4.9s;
  -o-transition-delay: 4.9s;
  transition-delay: 4.9s;
}
body.loaded .transition-delay-5000 {
  -webkit-transition-delay: 5s;
  -o-transition-delay: 5s;
  transition-delay: 5s;
}
body.loaded .transition-delay-5100 {
  -webkit-transition-delay: 5.1s;
  -o-transition-delay: 5.1s;
  transition-delay: 5.1s;
}
body.loaded .transition-delay-5200 {
  -webkit-transition-delay: 5.2s;
  -o-transition-delay: 5.2s;
  transition-delay: 5.2s;
}
body.loaded .transition-delay-5300 {
  -webkit-transition-delay: 5.3s;
  -o-transition-delay: 5.3s;
  transition-delay: 5.3s;
}
body.loaded .transition-delay-5400 {
  -webkit-transition-delay: 5.4s;
  -o-transition-delay: 5.4s;
  transition-delay: 5.4s;
}
body.loaded .transition-delay-5500 {
  -webkit-transition-delay: 5.5s;
  -o-transition-delay: 5.5s;
  transition-delay: 5.5s;
}
body.loaded .transition-delay-5600 {
  -webkit-transition-delay: 5.6s;
  -o-transition-delay: 5.6s;
  transition-delay: 5.6s;
}
body.loaded .transition-delay-5700 {
  -webkit-transition-delay: 5.7s;
  -o-transition-delay: 5.7s;
  transition-delay: 5.7s;
}
body.loaded .transition-delay-5800 {
  -webkit-transition-delay: 5.8s;
  -o-transition-delay: 5.8s;
  transition-delay: 5.8s;
}
body.loaded .transition-delay-5900 {
  -webkit-transition-delay: 5.9s;
  -o-transition-delay: 5.9s;
  transition-delay: 5.9s;
}
body.loaded .transition-delay-6000 {
  -webkit-transition-delay: 6s;
  -o-transition-delay: 6s;
  transition-delay: 6s;
}
/*=======================================================================
18. Switcher Style
===================================================================*/
@media only screen and (max-width: 991px) {
  .sidebar-demo .demo_changer {
    top: 0!important;
  }
}
.demo_changer {
  background-color: #f0f0f0;
  height: 100vh;
  width: 240px;
  right: -240px;
  left: inherit;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  transition: all 0.5s ease-in-out;
}
.demo_changer.active {
  transform: translateX(-240px);
}
@media only screen and (max-width: 991px) {
  .demo_changer {
    top: 51px!important;
  }
}
@media only screen and (max-width: 767px) {
  .demo_changer {
    display: block!important;
  }
}
.demo_changer h4.switcher-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--bs-color-text-theme);
}
.demo_changer h5.switcher-title {
  font-size: 17px;
  font-weight: 600;
  color: var(--bs-color-text-theme);
  margin-bottom: 8px;
}
.demo_changer .demo-icon {
  position: absolute;
  left: -40px;
  top: 38px;
  background: var(--bs-default-theme);
}
.demo_changer .form_holder {
  background-color: transparent!important;
  cursor: inherit!important;
  border-radius: 0!important;
  padding: 30px 20px!important;
  width: 240px;
  float: inherit;
  height: 100%;
  overflow-y: scroll;
}
.demo_changer .form_holder .page-changer ul {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.demo_changer .form_holder .page-changer ul li {
  text-align: center;
  margin-bottom: 5px;
  position: relative;
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  font-size: 15px;
}
.demo_changer .form_holder .page-changer ul li a {
  display: block;
  color: var(--bs-color-text-theme);
}
.demo_changer .form_holder .page-changer ul li img {
  border: 0;
  height: auto;
  margin: 0;
  width: auto;
  border-radius: 5px;
}
.demo_changer .form_holder .page-changer ul li ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.demo_changer .form_holder .page-changer ul li ul li {
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
}
.demo_changer .form_holder .color-changer {
  background-color: transparent!important;
  margin-top: 0;
  padding: 0;
  margin-bottom: 40px;
}
.demo_changer .form_holder .color-changer ul {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.demo_changer .form_holder .color-changer ul li {
  margin-bottom: 5px;
  position: relative;
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
  -ms-flex: 0 0 16.666667%;
  -webkit-box-flex: 0;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.demo_changer .form_holder .color-changer ul li a {
  display: block;
}
.demo_changer .form_holder .color-changer ul li a img {
  border-radius: 50%;
}
.demo_changer .btn-wrap {
  text-align: center;
  right: 0;
  left: 0;
  position: absolute;
  bottom: 0;
  margin-bottom: 15px;
  padding: 0 20px;
}
.rtl-demo .demo_changer {
  left: -240px;
  right: inherit;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.rtl-demo .demo_changer .demo-icon {
  left: 240px;
  border-radius: 0 20px 20px 0;
}
.rtl-demo .demo_changer.active {
  -webkit-transform: translateX(240px);
  -ms-transform: translateX(240px);
  transform: translateX(240px);
}